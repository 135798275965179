import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { AlgorithmSteps } from '../organisms/PsihologSeksolog/AlgorithmSteps'
import { Chance } from '../organisms/PsihologSeksolog/Chance'
import { ChooseSpecialist } from '../organisms/PsihologSeksolog/ChooseSpecialist'
import { Comments } from '../organisms/PsihologSeksolog/Comments'
import { HeroBlock } from '../organisms/PsihologSeksolog/HeroBlock'
import { HowItSection } from '../organisms/HowItSection'
import { HowSessionIsGoing } from '../organisms/PsihologSeksolog/HowSessionIsGoing'
import { PsychologistsSeksolog } from '../organisms/PsihologSeksolog/PsychologistsSeksolog'
import { QuestionListSeksolog } from '../molecules/QuestionListSeksolog'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { SpecialistHelp } from '../organisms/PsihologSeksolog/SpecialistHelp'
import { WhenConsultationNeed } from '../organisms/PsihologSeksolog/WhenConsultationNeed'
import { WhyYouNeedSeksolog } from '../organisms/PsihologSeksolog/WhyYouNeedSeksolog'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: var(--height);
  background: #dfebf9;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

const PshihologSeksolog = ({ data }) => (
  <App>
    <SEO
      description="Онлайн консультации сексолога ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="Психолог сексолог онлайн — подбор и консультация сексопатолога дистанционно | YouTalk"
    />
    <Page>
      <Header />
      <Main>
        <HeroBlock />
        <SpecialistHelp />
        <ChooseSpecialist />
        <PsychologistsSeksolog data={pick(['psychologists'], data)} />
        <WhenConsultationNeed />
        <WhyYouNeedSeksolog />
        <AlgorithmSteps />
        <HowSessionIsGoing />
        <Comments />
        <Chance />
        <QuestionListSeksolog />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default PshihologSeksolog

export const query = graphql`
  query PshihologSeksolog {
    psychologists: allPsychologistsData(
      filter: {
        nearest_slot: { ne: null }
        visibility: { eq: "OPEN" }
        professions: { elemMatch: { profession_name: { in: "Сексолог" } } }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
