export const commentsCard = [
  {
    img: 'person10.svg',
    imgAlt:
      'Портрет темноволосового кудрявого мужчины с усами и в очках на светло-оранжевом фоне',
    name: 'Александр',
    age: '27 лет',
    title: 'Сервис очень внимательно относится к деталям',
    desc: 'Сервис очень внимательно относится к деталям, что для меня ценно. Записаться на удобное время можно самостоятельно, сменить психолога тоже можно самому, оплатить сессию с телефона. А если возникают сложности, то служба поддержки моментально отвечает в ватсапп и телеграм.'
  },
  {
    img: 'person7.svg',
    imgAlt: 'Портрет темноволосой девушки на желтом фоне',
    name: 'Лариса',
    age: '25 лет',
    title: 'Нашли для меня замечательного специалиста',
    desc: 'Хочу поблагодарить консультантов за помощь с записью к психологу! Было сложно самой разобраться к кому мне идти со своей проблемой. У меня спросили пожелания, комфортную стоимость и удобное время, и нашли для меня замечательного специалиста!'
  },
  {
    img: 'person3.svg',
    imgAlt: 'Портрет лысого мужчины с телефоном на зеленом фоне',
    name: 'Сергей',
    age: '35 лет',
    title:
      'Позволяет быстро и конфиденциально подобрать подходящего специалиста',
    desc: 'Сервис действительно удобен в использовании! Я смог легко найти психолога, ориентируясь на его специализацию и стоимость услуг. Это особенно важно для тех, кто впервые обращается за помощью, поскольку позволяет быстро и конфиденциально подобрать подходящего специалиста.'
  },
  {
    img: 'person0.svg',
    imgAlt: 'Портрет девушки с розовыми волосами на зеленом фоне',
    name: 'Алёна',
    age: '30 лет',
    title: 'Рада, что обратилась именно в Юток.',
    desc: 'Рада, что обратилась именно в Юток.'
  },
  {
    img: 'person6.svg',
    imgAlt: 'Портрет женщины со светлыми волосами на синем фоне',
    name: 'Екатерина',
    age: '32 года',
    title: 'Я быстро нашла подходящего специалиста',
    desc: 'Поиск психолога был для меня очень стрессовым, но благодаря удобному фильтру по специализации, опыту и методам работы я быстро нашла подходящего специалиста. '
  },
  {
    img: 'person1.svg',
    imgAlt: 'Портрет девушки в очках с черными волосами на красном фоне',
    name: 'Алина',
    age: '35 лет',
    title: 'Процесс подбора был простым и удобным',
    desc: 'Сервис YouTalk помог мне найти отличного сексолога. Процесс подбора был простым и удобным. Я быстро заполнила анкету, и в течение нескольких минут получила несколько рекомендаций.'
  }
]

export const breakpointColumnsObj = {
  default: 3,
  1023: 2
}

export const paramsComments = {
  loop: false,
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
  preventClicks: false,
  preventClicksPropagation: false
}
