import heroIcon from '../../../../static/img/psiholog-seksolog/hero-image.svg'
import mobileHeroIcon from '../../../../static/img/psiholog-seksolog/hero-image-mobile.svg'
import smallHeroIcon from '../../../../static/img/psiholog-seksolog/hero-image-small.svg'
import styled from 'styled-components'
import { Button } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${size.lg}) {
    padding: 72px 0;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0 32px;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
    flex-direction: column;
  }
`

export const TitleContent = styled(Content)`
  @media (max-width: ${size.sm}) {
    flex-direction: column-reverse;
  }
`

export const HeroImage = styled.img.attrs(() => ({}))`
  content: url(${heroIcon});
  width: 588px;
  height: 588px;

  @media (max-width: ${size.lg}) {
    content: url(${smallHeroIcon});
    width: 100%;
    height: 454px;
  }

  @media (max-width: ${size.md}) {
    content: url(${mobileHeroIcon});
  }

  @media (max-width: ${size.sm}) {
    height: 227px;
  }

  @media (max-width: ${size.xs}) {
    content: url(${heroIcon});
    height: 288px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${size.lg}) {
    order: -1;
  }

  @media (max-width: ${size.md}) {
    margin: 0 auto;
  }
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`

export const InfoContainer = styled.div`
  max-width: 578px;

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }
`
