import React from 'react'
import {
  CardContainer,
  Content,
  HeartImage,
  ListItem,
  ListWrapper,
  TitleCard,
  Wrapper
} from './SpecialistHelp.styles'
import { Text } from '../../../../youtalk-storybook/src/ui'

export const SpecialistHelp = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <HeartImage />
          <TitleCard>Прием у сексолога онлайн поможет:</TitleCard>
          <ListWrapper>
            <ListItem>
              <Text.Large semiBold>Найти баланс интимности в паре</Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>
                Справиться с негативными переживаниями о сексе и последствиями
                травматического опыта
              </Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>
                Найти свою сексуальность и справиться с психосоматическими
                сексуальными проблемами
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
